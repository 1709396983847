import { Link, navigate } from 'gatsby'
import { capitalize } from 'src/utils/formatted'
import { sanitize } from 'dompurify'
import type { BreadcrumbProps as UIBreadcrumbProps } from '@faststore/ui'

import charMap from './charmap.json'

import './styles.scss'

type ItemElement = {
  item: string
  name: string
  position: number
}

export interface BreadcrumbProps extends UIBreadcrumbProps {
  breadcrumbList: ItemElement[]
  type?: 'list' | 'single'
}

const buildUrl = (section: string, breadcrumbList: string[]) => {
  const indexPoint = breadcrumbList.findIndex(
    (breadcrumb) => breadcrumb === section
  )

  const urlSection = breadcrumbList.slice(0, indexPoint + 1)

  const url = urlSection
    .map((sectionToParse) =>
      sectionToParse
        .toLowerCase()
        .split('')
        .map(
          (char) =>
            (charMap as Record<string, string | undefined>)[char] ?? char
        )
        .join('')
    )
    .join('/')

  return `/${url}`
}

export const currentBreadcrumb = (
  breadcrumbList?: BreadcrumbProps['breadcrumbList']
) => {
  const list = breadcrumbList?.map((item) =>
    item.item.split('/').filter((name) => name !== '')
  )

  list?.sort((a: string[], b: string[]) => b.length - a.length)

  return list?.[0].map((section: string) => {
    return {
      label: capitalize(
        section.toLowerCase().replaceAll(' ', '-'),
        'breadcrumb'
      ),
      link: buildUrl(
        section.toLowerCase().replaceAll(' ', '-'),
        list?.[0].map((item) => item.toLowerCase().replaceAll(' ', '-'))
      ),
    }
  })
}

const Breadcrumb = ({ breadcrumbList, type = 'list' }: BreadcrumbProps) => {
  if (!breadcrumbList?.length) {
    return null
  }

  const bestBreadcrumbList =
    type === 'list'
      ? currentBreadcrumb(breadcrumbList)
      : [{ link: breadcrumbList[0].item, label: breadcrumbList[0].name }]

  return (
    <nav className="breadcrumb_pdp flex ppp:flex-col pp:flex-row mt-4">
      <div className="flex flex-wrap items-center">
        <button
          className="flex ppp:text-xs pp:text-sm items-center w-fit"
          onClick={() => navigate('/')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            viewBox="0 0 16 16"
            fill="none"
            className="w-4 h-4 min-w-[16px]"
          >
            <path
              fillRule="evenodd"
              clipRule="evenodd"
              d="M2.66675 13.7656C2.66675 14.1338 2.96522 14.4323 3.33341 14.4323H12.6667C13.0349 14.4323 13.3334 14.1338 13.3334 13.7656V7.76561H15.3334L8.44875 1.50695C8.1944 1.27552 7.80576 1.27552 7.55141 1.50695L0.666748 7.76561H2.66675V13.7656ZM12.0001 6.53696V13.099H4.00008V6.53696L8.00008 2.90096L12.0001 6.53696Z"
              fill="#687787"
            />
          </svg>
          <span className="font-roboto text-sm text-darkGray ml-1 line-clamp-1">
            Página inicial
          </span>
        </button>
        {bestBreadcrumbList?.map((item, i) => (
          <Link
            to={item.link}
            key={item.link}
            className="font-roboto text-sm text-darkGray flex items-center"
          >
            <p
              className={`before:content-['/'] before:mx-2 before:text-base max-w-[135px] md:max-w-none line-clamp-1 ${
                i === bestBreadcrumbList.length - 1
                  ? 'font-bold before:font-normal'
                  : ''
              }`}
              key={i}
            >
              {capitalize(
                sanitize ? sanitize(item.label) : item.label,
                'breadcrumb'
              )}
            </p>
          </Link>
        ))}
      </div>
    </nav>
  )
}

export default Breadcrumb
